/* Header */
 
.wrapper2 {
    height: 250px;
}
header {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
 }

 header h1 {
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    color: #021718;
    margin-bottom: 24px;
    display: inline-block;
    transition: 300ms;
 }

 header h1:hover {
    transform: translateY(-8px);
 }

 header p {
    font-size: 24px;
    line-height: 42px;
    color: #021718;
    /* width: 418px; */
 }

 .hero-content {
    height: 70px;
 }

 .hero-image > button > img {
    height: 4vw;
    width: 4vw;
 }